import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { purple } from '@mui/material/colors';
import {AlarmOn, Person, Replay, TextSnippet} from '@mui/icons-material';
import { useQuery } from '@apollo/client';
import { LOAD_DASHBOARD_DATA } from '../../queries/admin';
import { DashboardCard } from '../../component/admin/DashboardCard';
import dayjs from "dayjs";

export const AdminIndex = () => {

    const {data, loading} = useQuery(LOAD_DASHBOARD_DATA);

    return (
        <>
            <Box sx={{ mb: 5 }}>
                <Typography variant="h4" color="white">DASHBOARD</Typography>
                <Typography variant="subtitle1" color={purple[300]}>Welcome to your dashboard</Typography>
            </Box>

            <Grid container spacing={2}>
                <Grid item md={3} lg={3}>
                    <DashboardCard
                        icon={<AlarmOn sx={{ color: purple[300], mb: 1}} />}
                        loading={loading}
                        label="Sprints"
                        value={data?.loadDashboardData?.sprints} />
                </Grid>
                <Grid item md={3} lg={3}>
                    <DashboardCard
                        icon={<Person sx={{ color: purple[300], mb: 1}} />}
                        loading={loading}
                        label="Users"
                        value={data?.loadDashboardData?.users} />
                </Grid>
                <Grid item md={3} lg={3}>
                    <DashboardCard
                        icon={<Replay sx={{ color: purple[300], mb: 1}} />}
                        loading={loading}
                        label="Last sprint"
                        value={data?.loadDashboardData?.lastSprintDate
                            ? dayjs(data?.loadDashboardData?.lastSprintDate).format('MM-DD-YYYY HH:mm')
                            : '-'} />
                </Grid>
                <Grid item md={3} lg={3}>
                    <DashboardCard
                        icon={<TextSnippet sx={{ color: purple[300], mb: 1}} />}
                        loading={loading}
                        label="Total words written"
                        value={Intl.NumberFormat('en-US').format(data?.loadDashboardData?.words || 0)} />
                </Grid>
            </Grid>
        </>
    )

}