import {Avatar, Box, Checkbox, Paper, Typography} from '@mui/material';
import { purple } from '@mui/material/colors';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { USERS } from '../../queries/admin';
import {DataGrid, GridValueGetterParams} from '@mui/x-data-grid';
import { IUserDto } from '../../dto/user/user.dto';
import {getAvatar} from "../../config/discord-endpoints";
import {GridRenderCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import dayjs from "dayjs";
import {GridColumns} from "@mui/x-data-grid/models/colDef/gridColDef";
import { GridRowModel } from '@mui/x-data-grid/models/gridRows';

const columns: GridColumns = [
    {
        field: 'number' ,
        headerName: 'N.',
        filterable: false,
        valueGetter: (params) => params.api.getRowIndex(params.row.id) + 1

    },
    {
        field: 'username',
        headerName: 'Username',
        flex: 1,
        renderCell: ({ row }: GridRowModel) => (
            <>
                <Avatar src={getAvatar(row.id, row.avatar, row.guildAvatar)} alt={row.username} sx={{ mr: 2 }} />
                <Typography>{row.username}</Typography>
            </>
        )
    },
    {
        field: 'signUp',
        headerName: 'Sign up Date',
        width: 120,
        filterable: false,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => dayjs(params.row.signUp).format('MM-DD-YYYY')
    },
    {
        field: 'permission',
        headerName: 'Permissions',
        width: 120
    },
    {
        field: 'ping',
        headerName: 'Ping',
        align: 'center',
        sortable: false,
        width: 75,
        renderCell: (params: GridRenderCellParams) => <Checkbox checked={params.row.ping} />
    },
    {
        field: 'participations',
        headerName: 'Participations to sprint',
        width: 200,
        type: 'number'
    },
]

export const AdminUsers = () => {

    const {data, loading} = useQuery(USERS);

    const users = useMemo(() => {
        if (!loading && data && data.users) {
            return data.users.map((user: IUserDto, k: number) => ({
                number: k + 1,
                id: user.id,
                username: user.nick || `${user.username}#${user.discriminator}`,
                avatar: user.avatar,
                guildAvatar: user.guildAvatar,
                signUp: user.signUp,
                permission: user.permission,
                ping: user.alwaysPing || user.ping > 0,
                participations: user?.participations?.length
            }));
        }
    }, [data, loading]);

    return (
        <>
            <Box sx={{ mb: 5 }}>
                <Typography variant="h4" color="white">USERS</Typography>
                <Typography variant="subtitle1" color={purple[300]}>Every City of Progress' user (with at least 1 sprint)</Typography>
            </Box>

            <Paper>
                <DataGrid
                    rows={users || []}
                    columns={columns}
                    pageSize={25}
                    getRowId={(row) => row.id}
                    autoHeight />
            </Paper>
        </>
    )

}