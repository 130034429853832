import { gql } from '@apollo/client/core';

export const LOAD_DASHBOARD_DATA = gql`
    query loadDashboardData {
        loadDashboardData {
            users
            sprints
            lastSprintDate,
            words
        }
    }
`

export const USERS = gql`
    query users {
        users {
            id
            username
            discriminator
            nick
            avatar
            guildAvatar
            signUp
            permission
            alwaysPing
            ping
            currentWordCount
            
            participations {
                id
            }
        }
    }
`