import React, { useCallback, useContext } from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { NavLink } from './NavLink';
import { History, ShowChart } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { AuthContext, logout } from '../../../config/auth-manager';
import PermissionEnum from '../../../dto/user/permission.enum';
import {getAvatar} from "../../../config/discord-endpoints";
import { config } from '../../../config/config';

export const Header = () => {

    const location = useLocation();
    const {user} = useContext(AuthContext);

    const isActive = useCallback((partialPath: string): boolean => {
        return new RegExp('^/' + partialPath).test(location.pathname);
    }, [location.pathname]);

    return (
        <Paper square sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            boxShadow: '0',
            height: 72,
            px: 2
        }}>
            <Stack direction='row' spacing={4}>
                <Typography variant='h6' color='white'>City of Progress' sprints</Typography>
                <NavLink icon={<ShowChart />} label='Leaderboard' to='/leaderboard' isActive={isActive('leaderboard')} />
                <NavLink icon={<History />} label='My Sprints' to='/my-sprints' isActive={isActive('my-sprints')} />
                {user?.permission === PermissionEnum.ADMIN &&
                    <NavLink icon={<History />} label='Administration' to='/admin' isActive={isActive('admin')} />
                }
            </Stack>
            {user
                ? <Stack direction="row" spacing={2} alignItems="center">
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body1" color="white">{user?.username}#{user?.discriminator}</Typography>
                        <Box sx={{
                            height: 44,
                            width: 44,
                            border: '2px solid #ffffff',
                            borderRadius: '50%',
                            zIndex: 10
                        }}>
                            {user.username && user.discriminator &&
                                <img src={getAvatar(user.id, user.avatar)}
                                     height={40}
                                     width={40}
                                     alt={`Profile of ${user.id}#${user.avatar}`}
                                     style={{ borderRadius: '50%' }} />
                            }
                        </Box>
                    </Stack>
                    <Button variant='contained' onClick={() => logout()}>Log out</Button>
                </Stack>
                : <a href={`https://discord.com/api/oauth2/authorize?client_id=${config.DISCORD_CLIENT_ID}&redirect_uri=${config.DISCORD_REDIRECT_URL}&response_type=code&scope=identify`}>
                    <Button variant='contained'>Log in with Discord</Button>
                </a>
            }
        </Paper>
    );

};