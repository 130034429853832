import React, { FC, useCallback } from 'react';
import { Stack, Paper, Box, Typography, Skeleton } from '@mui/material';
import { IUserDto } from '../../dto/user/user.dto';
import { grey } from '@mui/material/colors';
import { AdminNavLink } from './AdminNavLink';
import { AlarmOn, FileUpload, Home, Person } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

interface IProps {
    user?: IUserDto;
}

export const AdminNavigation: FC<IProps> = ({ user }) => {

    const location = useLocation();

    const isActive = useCallback((partialPath: string): boolean => {
        return new RegExp('^/' + partialPath + '$').test(location.pathname);
    }, [location.pathname]);

    return (
        <Paper square sx={{
            width: '320px',
            boxShadow: '0'
        }}>
            <Stack direction="column" sx={{
                padding: 2
            }}>
                <Box sx={{ margin: '20px auto' }}>
                    {user
                        ?
                        <>
                            <Box sx={{
                                height: 123,
                                width: 123,
                                border: '3px solid #ffffff',
                                borderRadius: '50%',
                                margin: '0 auto 20px'
                            }}>
                                {user.username && user.discriminator &&
                                    <img src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.jpg`}
                                         height={116}
                                         width={116}
                                         alt={`Profile of ${user.id}#${user.avatar}`}
                                         style={{ borderRadius: '50%' }} />
                                }
                            </Box>
                            <Typography textAlign='center'>{user.nick || user.username}</Typography>
                            <Typography textAlign='center'
                                        color={grey[500]}>{`${user.username}#${user.discriminator}`}</Typography>
                        </>
                        :
                        <>
                            <Skeleton variant='circular' width={123} height={123} sx={{
                                border: '3px solid #ffffff',
                                margin: '20px auto'
                            }} />
                            <Skeleton variant='rounded' width={160} height={18} sx={{ mb: 1 }} />
                            <Skeleton variant='rounded' width={160} height={18} />
                        </>
                    }
                </Box>
                <Stack direction="column" spacing={1} sx={{ padding: 1 }}>
                    <AdminNavLink icon={<Home />} label="Dashboard" to="/admin" isActive={isActive('admin')} />
                    <Typography variant="overline">Data</Typography>
                    <AdminNavLink icon={<Person />} label="Users" to="/admin/users" isActive={isActive('admin/users')} />
                    <AdminNavLink icon={<AlarmOn />} label="Sprint" to="/admin/sprints" isActive={isActive('admin/sprints')} />
                    <AdminNavLink icon={<FileUpload />} label="Import Sprint data" to="/admin/import" isActive={isActive('admin/import')} />
                </Stack>
            </Stack>
        </Paper>
    );

};