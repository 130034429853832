import {createContext, FC, useCallback, useContext, useState} from "react";
import {Alert, AlertColor, Snackbar} from "@mui/material";

interface IContextProps {
    openSnackbar: (message: string, duration?: number, severity?: AlertColor) => void;
}

export const SnackbarContext = createContext<IContextProps>({} as IContextProps);

export const SnackbarProvider: FC<any> = ({ children }) => {

    const [open, setOpen] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [duration, setDuration] = useState<number>(5000);
    const [severity, setSeverity] = useState<AlertColor>('success');

    const openSnackbar = useCallback((message: string, duration: number = 5000, severity: AlertColor = 'success') => {
        setMessage(message);
        setDuration(duration);
        setSeverity(severity);
        setOpen(true);
    }, []);

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <SnackbarContext.Provider value={{ openSnackbar }}>
            <Snackbar
                open={open}
                autoHideDuration={duration}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center'}}>
                <Alert onClose={handleClose} severity={severity} variant="filled" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
            { children }
        </SnackbarContext.Provider>
    )

}

export const useSnackBar = (): IContextProps => {
    const context = useContext(SnackbarContext);

    if (!context) {
        throw new Error('useSnackBar must be used within an SnackBarProvider');
    }

    return context;
};