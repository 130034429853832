import * as React from 'react';

const Login = () => {

    return (
        <p>Welcome</p>
    )

}

export default Login;