import React, { useContext, useEffect } from 'react';
import {Box, Stack} from '@mui/material';
import {AdminNavigation} from "../../component/admin/AdminNavigation";
import { AuthContext } from '../../config/auth-manager';
import { redirect, Outlet } from 'react-router-dom';

export const Admin = () => {

    const {user, loading} = useContext(AuthContext);

    useEffect(() => {
        if (!loading && !user) redirect('/')
    }, [user, loading])

    return (
        <Stack direction="row" sx={{
            minHeight: 'calc(100vh - 72px)'
        }}>
            <AdminNavigation user={user} />
            <Box sx={{
                padding: '40px',
                width: 1
            }}>
                <Outlet />
            </Box>
        </Stack>
    )

}