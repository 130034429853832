import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { grey, purple } from '@mui/material/colors';

interface IProps {
    icon: JSX.Element;
    label: string;
    to: string;
    isActive: boolean;
}

export const AdminNavLink: FC<IProps> = ({ icon, label, to, isActive }) => {

    return (
        <Link to={to} className="Navigation-link">
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',

                position: 'relative',

                color: isActive ? purple[300] : grey[500],
                mb: 1,
                transition: '0.3s color',
                width: 'fit-content',

                '&:hover': {
                    color: purple[300],
                    transition: '0.3s color',
                }
            }}>
                {icon}
                <Typography sx={{ ml: 2 }}>{label}</Typography>
            </Box>
        </Link>
    )

}