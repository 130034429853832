import { TextField, Theme } from '@mui/material';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import React, { FC } from 'react';
import { SxProps } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';

interface props {
    control: Control<any>;
    name: string;
    label?: string;
    required?: boolean;
    minDateTime?: Date;
    maxDateTime?: Date;
    sx?: SxProps<Theme>;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    defaultValue?: Date;
}

export const AppDateTimePicker: FC<props> = ({
                                                 control,
                                                 name,
                                                 label,
                                                 required = false,
                                                 minDateTime,
                                                 maxDateTime,
                                                 sx,
                                                 rules,
                                                 defaultValue
                                             }) => {

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue ?? ''}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={label}
                        value={value}
                        onChange={(value) => onChange(value, 'date')}
                        minDateTime={minDateTime}
                        maxDateTime={maxDateTime}
                        inputRef={ref}
                        renderInput={(params) =>
                            <TextField variant='filled' required={required} sx={sx} {...params} />
                        } />
                </LocalizationProvider>
            )}
            rules={rules}
        />
    );

};