import React, { FC, useCallback, useEffect, useState } from 'react';
import { Autocomplete, Avatar, Stack, TextField, Typography } from '@mui/material';
import { debounce } from 'lodash';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_MEMBERS } from '../../../queries/discord';
import { GuildMemberDto } from '../../../dto/discord/discord-user.dto';
import { getAvatar } from '../../../config/discord-endpoints';
import { Controller, Control } from 'react-hook-form';

interface IProps {
    name: string;
    control: Control;
}

export const GuildMemberAutocomplete: FC<IProps> = ({ name, control }) => {

    const [options, setOptions] = useState<GuildMemberDto[]>([]);
    const [inputValue, setInputValue] = useState<string>('');
    const [oldInputValue, setOldInputValue] = useState<string>('');
    const [searchMembers] = useLazyQuery(SEARCH_MEMBERS);

    const debounceSearchMember = useCallback(
        debounce(async (search: string) => {
            if (search !== '' && search !== oldInputValue) {
                const response = await searchMembers({ variables: { search } });
                setOldInputValue(search);
                setOptions(response.data.searchMember);
            }
            if (search === '') setOptions([]);
        }, 500),
        []);

    useEffect(() => {
        debounceSearchMember(inputValue);
    }, [inputValue, debounceSearchMember]);

    const getLabel = (option: GuildMemberDto | string): string => {
        if (typeof option === 'string') return option;
        else if (!option || !option.username) return '';
        return option?.username;
    };

    const handleOptionIsEqualToValue = (option: GuildMemberDto, value: GuildMemberDto): boolean => {
        return (option?.id === value?.id)
    }



    return (
        <Controller
            name={name}
            control={control}
            defaultValue={''}
            render={({ field: { onChange, value }}) => (
                <Autocomplete
                    freeSolo
                    options={[...options]}
                    getOptionLabel={getLabel}
                    multiple={false}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                            <Stack direction='row' spacing={2} alignItems='center'
                                   sx={{
                                       height: 40,
                                   }}>
                                <Avatar
                                    src={getAvatar(option.id, option.avatar)} alt={option.username}
                                    sx={{ height: 32, width: 32 }} />
                                <Typography>{option?.username}</Typography>
                            </Stack>
                        </li>
                    )}
                    inputValue={inputValue}
                    onInputChange={(e, newValue) => setInputValue(newValue)}
                    isOptionEqualToValue={(opt, value) => handleOptionIsEqualToValue(opt, value)}
                    onChange={(e, newValue: string | GuildMemberDto[] | GuildMemberDto) =>
                        typeof newValue !== 'string' && !Array.isArray(newValue) && onChange({
                            avatar: newValue.avatar,
                            username: newValue.username,
                            id: newValue.id
                        }
                    )}
                    value={value as GuildMemberDto}
                    renderInput={(params) =>
                        <TextField variant='filled' label='Sprinter' {...params} sx={{ minWidth: 288 }} />
                    } />
            )} />
    );

};
