import { createTheme } from '@mui/material';
import { amber, orange, purple, red } from '@mui/material/colors';

const muiTheme = createTheme({
    palette: {
        mode: 'dark',
        background: {
            default: '#302230',
            paper: '#322132'
        },
        primary: purple,
        secondary: amber,
        warning: orange,
        error: red,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '18px'
                }
            }
        }
    }
});

export default muiTheme;