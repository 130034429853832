import React, { FC } from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { GuildMemberAutocomplete } from './GuildMemberAutocomplete';
import { AppTextField } from '../../form/AppTextField';
import { AppTimePicker } from '../../form/AppTimePicker';
import dayjs from 'dayjs';
import { Control } from 'react-hook-form';

interface IProps {
    control: Control<any>;
    index: number;
    isDeletable: boolean;
    handleDeleteRow: (index: number) => void;
}

export const AdminSprintFormRow: FC<IProps> = ({ control, index, isDeletable, handleDeleteRow }) => {

    return (
        <Stack direction='row' spacing={3} alignItems='flex-start' sx={{
            '&:hover button': {
                opacity: 1
            }
        }}>
            {isDeletable ?
                <IconButton onClick={() => handleDeleteRow(index)} sx={{
                    opacity: 0,
                    margin: '8px -8px 8px 8px',
                }}>
                    <Clear />
                </IconButton>
                :
                <Box sx={{ width: 40 }} />
            }
            <GuildMemberAutocomplete control={control} name={`participations.${index}.user`} />
            <AppTextField
                required={index === 0}
                type='number'
                name={`participations.${index}.words`}
                control={control}
                label='Word count'
                rules={{
                    required: index === 0
                }}
                sx={{ width: 164 }} />
            <AppTimePicker
                control={control}
                label='Joining Time'
                defaultValue={dayjs().toDate()}
                name={`participations.${index}.join`}
                sx={{ width: 164 }} />
        </Stack>
    )

}