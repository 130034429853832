import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IUserDto } from '../dto/user/user.dto';
import { useLocation } from 'react-router-dom';
import { JwtToken } from '../dto/user/jwt.dto';
import { useLazyQuery } from '@apollo/client';
import { VERIFY_TOKEN } from '../queries/auth';

export const AuthContext = createContext<{
    user: IUserDto | null,
    loading: boolean,
    setUser: Dispatch<SetStateAction<IUserDto>> | null
}>({ user: null, loading: false, setUser: null });

export const AuthProvider = ({ children }: any) => {
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState<IUserDto | null>(null);
    const [verifyToken] = useLazyQuery(VERIFY_TOKEN);
    const location = useLocation();

    useEffect(() => {
        setLoading(true);
        const tokenString: string | null = localStorage.getItem('sprinto-logs-token');
        if (tokenString) {
            if (location.pathname === '/login') {
                window.location.href = '/';
            }
            const token: JwtToken = JSON.parse(tokenString);
            verifyToken({ variables: { token: token.token } }).then(res => {
                if (res && res.data && res.data.verifyToken && !res.error) {
                    setUser(res.data.verifyToken)
                    setLoading(false)
                } else {
                    localStorage.removeItem('sprinto-logs-token');
                    window.location.href = '/login';
                    setLoading(false)
                }
            });
        }
    }, [location.pathname, verifyToken]);

    return (
        <AuthContext.Provider value={{ user, setUser, loading }}>
            {children}
        </AuthContext.Provider>
    );
};

export const logout = () => {
    localStorage.removeItem('sprinto-logs-token');
    window.location.href = '/';
};