import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import { Header } from './component/layout/header/Header';
import { AuthProvider } from './config/auth-manager';
import { Box, ThemeProvider } from '@mui/material';
import muiTheme from './config/mui-theme';
import { Leaderboard } from './pages/leaderboard/Leaderboard';
import { MySprints } from './pages/my-sprints/MySprints';
import { Admin } from './pages/admin/Admin';
import { AdminIndex } from './pages/admin/AdminIndex';
import { AdminUsers } from './pages/admin/AdminUsers';
import { AdminImportData } from './pages/admin/AdminImportData';
import { AdminSprints } from './pages/admin/AdminSprints';
import { SnackbarProvider } from './component/layout/SnackbarContext';

const App = () => {

    return (
        <ThemeProvider theme={muiTheme}>
            <Router>
                <SnackbarProvider>
                    <AuthProvider>
                        <Box sx={{ backgroundColor: 'background.default', minHeight: '100vh' }}>
                            <Header />

                            <Routes>
                                <Route path='/login' element={<Login />} />
                                <Route path='/leaderboard' element={<Leaderboard />} />
                                <Route path='/my-sprints' element={<MySprints />} />
                                <Route path='/admin' element={<Admin />}>
                                    <Route index element={<AdminIndex />} />
                                    <Route path='users' element={<AdminUsers />} />
                                    <Route path='sprints' element={<AdminSprints />} />
                                    <Route path='import' element={<AdminImportData />} />
                                </Route>
                                <Route path='/' element={<Home />} />
                            </Routes>
                        </Box>
                    </AuthProvider>
                </SnackbarProvider>
            </Router>
        </ThemeProvider>
    );
};

export default App;
