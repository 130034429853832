import { TextField, Theme } from '@mui/material';
import {Controller, Control, RegisterOptions} from "react-hook-form";
import React, {FC} from "react";
import {SxProps} from "@mui/system";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers';

interface props {
    control: Control;
    name: string;
    label?: string;
    minTime?: Date;
    maxTime?: Date;
    required?: boolean;
    sx?: SxProps<Theme>;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    defaultValue?: Date;
}

export const AppTimePicker: FC<props> = ({
    control,
    name,
    label,
    minTime,
    maxTime,
    sx,
    rules,
    defaultValue
}) => {

    return (
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue ?? ''}
                render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            label={label}
                            onChange={(value) => onChange(value)}
                            value={value}
                            inputRef={ref}
                            minTime={minTime}
                            maxTime={maxTime}
                            renderInput={(params) =>
                                <TextField
                                    variant="filled"
                                    sx={sx} {...params} />
                            }/>
                    </LocalizationProvider>
                )}
                rules={rules}
            />
    )

}