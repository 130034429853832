import { Paper, Skeleton, Stack, Typography } from '@mui/material';
import { purple } from '@mui/material/colors';
import React, { FC } from 'react';

interface IProps {
    icon: JSX.Element;
    loading: boolean;
    label: string;
    value: string;
}

export const DashboardCard: FC<IProps> = ({ icon, loading, label, value }) => {

    return (
        <Paper sx={{ p: 2 }}>
            <Stack direction="row" justifyContent="space-between">
                <Stack direction="column">
                    {icon}
                    {loading
                        ? <Skeleton width={80} height={18} />
                        : <Typography variant="h6" color="#ffffff">{value}</Typography>
                    }
                    <Typography variant="subtitle2" color={purple[300]}>{label}</Typography>
                </Stack>
            </Stack>
        </Paper>
    )

}