import React, { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Grid, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMutation } from '@apollo/client';
import { IMPORT_SPRINT_DATA } from '../../queries/file';
import { useSnackBar } from '../layout/SnackbarContext';
import { LoadingButton } from '@mui/lab';
import { Send } from '@mui/icons-material';

export const ImportDataCard = () => {

    const [selectedFile, setSelectedFile] = useState<File>(null);
    const [typeError, setTypeError] = useState<boolean>(false);
    const [fileImportMutation, {loading}] = useMutation(IMPORT_SPRINT_DATA);
    const {openSnackbar} = useSnackBar();

    useEffect(() => {
        if (selectedFile && selectedFile.type !== 'text/csv') {
            setSelectedFile(null);
            setTypeError(true)
        } else if (selectedFile && selectedFile.type === 'text/csv') {
            setTypeError(false);
        }
    }, [selectedFile])

    const handleFileUpload = () => {
        if (!selectedFile) return;

        fileImportMutation({
            variables: {
                file: selectedFile
            }
        }).then(() => {
            openSnackbar('Data imported successfully!')
        })
    }

    return (
        <Grid item md={6} lg={4}>
            <Card elevation={3}>
                <CardHeader title='Import Data' />
                <CardContent>
                    <Stack justifyContent='center' alignItems='center' sx={{
                        position: 'relative',
                        border: `3px ${selectedFile?.name ? 'solid #fff' : `dashed ${grey[500]}`}`,
                        borderRadius: 1,
                        width: 1,
                        height: 120,
                        transition: 'border 0.3s',

                        '&:hover': {
                            borderColor: '#fff'
                        }
                    }}>
                        <Typography sx={{ fontStyle: 'italic' }} color={selectedFile?.name ? 'fff' : grey[500]}>
                            {selectedFile?.name || 'No file selected...'}
                        </Typography>
                        <input type='file'
                               onChange={(e) => setSelectedFile(e.target.files[0])}
                               style={{
                                   position: 'absolute',
                                   opacity: 0,
                                   width: '100%',
                                   height: '100%',
                                   cursor: 'pointer',
                                   verticalAlign: 'middle'
                               }} />
                    </Stack>
                    { typeError &&
                        <Typography variant="body2" align="justify" sx={{ color: 'warning.main', fontStyle: 'italic', mt: 1 }}>
                            Please provide a .csv file. Other file extension is not allowed.
                        </Typography>
                    }
                </CardContent>
                <CardActions sx={{
                    justifyContent: 'flex-end'
                }}>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        variant="contained"
                        startIcon={<Send />}
                        disabled={!selectedFile}
                        onClick={handleFileUpload}>
                        Import file
                    </LoadingButton>
                </CardActions>
            </Card>
        </Grid>
    );

};