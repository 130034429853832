import React, { Dispatch, FC, memo, SetStateAction, useEffect, useMemo } from 'react';
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack } from '@mui/material';
import dayjs from 'dayjs';
import { Add } from '@mui/icons-material';
import { IFormSprintDto } from '../../../dto/sprint/sprint.dto';
import { AppTextField } from '../../form/AppTextField';
import { useForm, useWatch } from 'react-hook-form';
import { IFormParticipationDto } from '../../../dto/participation/participation.dto';
import { AppDateTimePicker } from '../../form/AppDateTimePicker';
import { AdminSprintFormRow } from './AdminSprintFormRow';
import { useMutation } from '@apollo/client';
import { CREATE_SPRINT } from '../../../queries/sprint';
import { useSnackBar } from '../../layout/SnackbarContext';
import { GridSortModel } from '@mui/x-data-grid';
import { SprintStateEnum } from '../../../dto/sprint/sprint-state.enum';

interface IProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    handleReFetch: (newSortModel?: GridSortModel) => void;
}

export const AdminSprintForm: FC<IProps> = memo(({ open, setOpen, handleReFetch }) => {

    const { control, getValues, handleSubmit, setValue } = useForm({
        mode: 'onTouched',
        defaultValues: useMemo<IFormSprintDto>(() => ({
            date: dayjs().toDate(),
            participations: [{
                join: dayjs().toDate()
            } as IFormParticipationDto]
        } as IFormSprintDto), [])
    });
    const participations = useWatch({ control, name: 'participations' });
    const sprintDate = useWatch({ control, name: 'date' });
    const [createSprint, { error }] = useMutation(CREATE_SPRINT);
    const { openSnackbar } = useSnackBar();

    useEffect(() => {
        const lastParticipation = participations[participations.length - 1];
        if (lastParticipation.user?.id || lastParticipation.words) {
            const newParticipations = [...participations];
            newParticipations.push({
                join: sprintDate
            } as IFormParticipationDto);
            setValue('participations', newParticipations);
        }
    }, [participations, sprintDate, setValue]);

    const handleFormSubmit = async (data: IFormSprintDto): Promise<void> => {
        data.duration = +data.duration
        data.state = SprintStateEnum.FINISHED;
        data.participations = data.participations.map(p => {
            if (p.user && p.words && p.user !== '' && (p.words as unknown) !== '' && !isNaN(+p.words)) {
                p.words = +p.words
                return p;
            }
            return null
        }).filter(p => p);

        const response = await createSprint({
            variables: {
                sprint: data
            }
        });
        if (response && !error) {
            handleReFetch()
            openSnackbar('Sprint created successfully!')
            setOpen(false);
        }
    };

    const handleDeleteRow = (index: number) => {
        if (participations.length > 1) {
            setValue('participations', participations.slice(index, index + 1))
        }
    }

    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth='lg'>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogTitle>Add a new Sprint</DialogTitle>
                <DialogContent>
                    <>
                        <Stack direction='row' spacing={3} sx={{ mb: 2 }}>
                            <AppDateTimePicker
                                control={control}
                                defaultValue={dayjs().toDate()}
                                label='Sprint Date'
                                maxDateTime={dayjs().toDate()}
                                name='date'
                                required
                                sx={{ minWidth: 352 }} />
                            <AppTextField
                                required
                                type='number'
                                name='duration'
                                control={control}
                                label='Duration (in minutes)'
                                rules={{
                                    required: true
                                }}
                                sx={{ minWidth: 352 }} />
                        </Stack>
                        <Divider sx={{ mb: 2 }}>
                            <Chip label='Sprint participations' />
                        </Divider>

                        <Stack direction='column' spacing={4}>
                            {getValues().participations.map((participation, k) => (
                                <AdminSprintFormRow
                                    control={control}
                                    index={k}
                                    isDeletable={participations.length > 1}
                                    handleDeleteRow={handleDeleteRow}
                                    key={k} />
                            ))}
                        </Stack>
                    </>
                </DialogContent>
                <DialogActions>
                    <Button type='submit' variant='contained' startIcon={<Add />}>CREATE NEW SPRINT</Button>
                </DialogActions>
            </form>
        </Dialog>
    );

});