import {FilledInput, FormControl, FormHelperText, InputLabel, Theme} from "@mui/material";
import {Controller, Control, RegisterOptions} from "react-hook-form";
import React, {FC, KeyboardEvent} from "react";
import {SxProps} from "@mui/system";

interface props {
    control: Control<any>;
    name: string;
    label?: string;
    type?: string;
    multiline?: boolean;
    required?: boolean;
    size?: 'small' | 'medium';
    onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    sx?: SxProps<Theme>;
    rules?: Omit<RegisterOptions, 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>;
    defaultValue?: string | number;
}

export const AppTextField: FC<props> = ({
    control,
    name,
    type = 'text',
    label,
    multiline = false,
    required = false,
    size = 'medium',
    onKeyDown,
    sx,
    rules,
    defaultValue
}) => {

    return (
        <FormControl size={size} sx={sx} variant="filled" required={required}>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue ?? ''}
                render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
                    <>
                        <InputLabel htmlFor={name + '-id'}>{label}</InputLabel>
                        <FilledInput
                            id={name + '-id'}
                            value={value}
                            required={required}
                            onChange={onChange}
                            error={!!error}
                            inputRef={ref}
                            type={type}
                            onKeyDown={onKeyDown}
                            aria-describedby={name + '-text'}
                            multiline={multiline}
                        />
                        <FormHelperText error={true} id={name + '-text'}>{error ? error.message : null}</FormHelperText>
                    </>
                )}
                rules={rules}
            />
        </FormControl>
    )

}