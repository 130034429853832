import React, {useEffect, useState} from 'react';
import {
    Button,
    Paper,
    Stack,
    Typography
} from '@mui/material';
import {purple} from '@mui/material/colors';
import { useLazyQuery } from '@apollo/client';
import {SPRINTS} from '../../queries/sprint';
import {DataGrid, GridSortModel, GridValueGetterParams} from '@mui/x-data-grid';
import {ISprintDto} from '../../dto/sprint/sprint.dto';
import {Add} from '@mui/icons-material';
import {AdminSprintForm} from "../../component/admin/sprint/AdminSprintForm";
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {GridColumns} from "@mui/x-data-grid/models/colDef/gridColDef";

dayjs.extend(duration)
dayjs.extend(relativeTime)

const columns: GridColumns = [
    {
        field: 'date',
        headerName: 'Date',
        width: 200,
        hide: false,
        valueGetter: (params: GridValueGetterParams) => dayjs(params.row.date).format('MM-DD-YYYY HH:mm'),
        sortComparator: (v1, v2) => dayjs(v2).isBefore(dayjs(v1), 'day') ? 1 : -1
    },
    {
        field: 'duration',
        headerName: 'Duration',
        valueGetter: (params: GridValueGetterParams) => dayjs.duration(params.row.duration, "minutes").humanize()
    },
    {field: 'state', headerName: 'Sprint state'},
    {
        field: 'totalWords',
        headerName: 'Total words',
        align: 'right',
        type: 'number',
        width: 160,
        valueGetter: (params: GridValueGetterParams) => params.row.totalWords || 0
    },
    {field: 'countParticipations', headerName: 'Participants'},
]

export const AdminSprints = () => {

    const [sprints, setSprints] = useState<ISprintDto[]>([]);
    const [countSprints, setCountSprints] = useState<number>(0);
    const [page, setPage] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [sortModel, setSortModel] = useState<GridSortModel>([{
        field: 'date',
        sort: 'desc'
    }]);

    const [fetchSprints, {data, refetch}] = useLazyQuery<{ sprints: ISprintDto[], countSprints: number }>(SPRINTS);

    useEffect(() => {
        fetchSprints({
            variables: {
                page,
                field: sortModel?.[0]?.field || 'date',
                sort: sortModel?.[0]?.sort.toUpperCase() || 'DESC'
            }
        })
    }, [page, fetchSprints]);

    useEffect(() => {
        if (data?.sprints) setSprints(data.sprints)
        if (data?.countSprints) setCountSprints(data.countSprints)
    }, [data]);

    const handleReFetch = (newSortModel?: GridSortModel): void => {
        if (!newSortModel) newSortModel = sortModel
        refetch({
            page,
            field: newSortModel?.[0]?.field || 'date',
            sort: newSortModel?.[0]?.sort.toUpperCase() || 'DESC'
        }).then(() => setSortModel(newSortModel));
    }

    return (
        <>
            <AdminSprintForm
                open={open}
                setOpen={setOpen}
                handleReFetch={handleReFetch} />

            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{mb: 5}}>
                <div>
                    <Typography variant="h4" color="white">SPRINTS</Typography>
                    <Typography variant="subtitle1" color={purple[300]}>Every City of Progress' sprint</Typography>
                </div>
                <Button variant="contained" startIcon={<Add/>} onClick={() => setOpen(true)}>CREATE A NEW
                    SPRINT</Button>
            </Stack>

            <Paper>
                <DataGrid
                    autoHeight
                    columns={columns}
                    pageSize={25}
                    paginationMode="server"
                    rowCount={countSprints}
                    rows={sprints}
                    sortModel={sortModel}
                    getRowId={(row) => row.id}
                    onPageChange={(page) => setPage(page)}
                    onSortModelChange={(newSM: GridSortModel) => handleReFetch(newSM || {} as GridSortModel)}/>
            </Paper>
        </>
    )

}