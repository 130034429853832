import { gql } from '@apollo/client/core';

export const SEARCH_MEMBERS = gql`
    query searchMembers($search: String!) {
        searchMember(search: $search) {
            id
            avatar
            username
        }
    }
`