import { gql } from '@apollo/client/core';

export const LOGIN = gql`
    query login($input: String!) {
        login(code: $input) {
            expiresIn,
            token,
            user {
                id
                username
                discriminator
                nick
                avatar
                guildAvatar
                permission
                signUp
                lastConnection
                ping
                alwaysPing
            }
        }
    }
`;

export const VERIFY_TOKEN = gql`
    query verifyToken($token: String!) {
        verifyToken(token: $token) {
            id
            username
            discriminator
            nick
            avatar
            guildAvatar
            permission
            signUp
            lastConnection
            ping
            alwaysPing
        }
    }
`;