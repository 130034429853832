import { Link } from 'react-router-dom';
import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import {purple} from '@mui/material/colors';

interface IProps {
    icon: JSX.Element;
    label: string;
    to: string;
    isActive: boolean;
}

export const NavLink: FC<IProps> = ({ icon, label, to, isActive }) => {

    return (
        <Link to={to} className="Navigation-link">
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',

                position: 'relative',

                background: `linear-gradient(to top, #ffffff 0%, #ffffff 2px, transparent 3px) no-repeat`,
                width: 'fit-content',
                padding: '4px 0',
                color: isActive ? '#ffffff' : purple[200],
                transition: '0.3s color, 0.3s background-size',
                backgroundSize: isActive ? '100% 100% !important' : '0 100% !important',

                '&:hover': {
                    color: '#ffffff',
                    transition: '0.3s color, 0.3s background-size',
                    backgroundSize: '100% 100% !important'
                }
            }}>
                {icon}
                <Typography sx={{ ml: 1 }}>{label}</Typography>
            </Box>
        </Link>
    )

}