import { gql } from '@apollo/client/core';

export const SPRINTS = gql`
    query sprints($page: Float, $items: Float, $field: String, $sort: String) {
        sprints(page: $page, items: $items, field: $field, sort: $sort) {
            id
            date
            duration
            cancelled
            totalWords
            state

            countParticipations
        }
        countSprints
    }
`;

export const CREATE_SPRINT = gql`
    mutation createSprint($sprint: CreateSprintInput!) {
        createSprint(sprint: $sprint) {
            id
        }
    }
`;