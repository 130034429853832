import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from 'apollo-upload-client';
import { JwtToken } from '../dto/user/jwt.dto';
import {logout} from "./auth-manager";
import { config } from './config';

const authLink = setContext((_, { headers }) => {
    const token: string | null = localStorage.getItem('sprinto-logs-token');

    if (token) {
        const authToken: JwtToken = JSON.parse(token)
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${authToken.token}` : "",
            }
        }
    }
    return {
        headers: {
            ...headers
        }
    }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        graphQLErrors.forEach(error => {
            console.error(error)
            // @ts-ignore
            if (error.extensions && error.extensions.exception.response.statusCode === 401) {
                logout();
            }
        });

    if (networkError) console.error(`[Network error]: ${networkError}`);
});

export const client = new ApolloClient({
    link: ApolloLink.from([
        errorLink,
        authLink,
        createUploadLink({ uri: config.API_URL })
    ]),
    cache: new InMemoryCache()
});