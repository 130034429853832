import * as React from 'react';
import { useSearchParams } from "react-router-dom";
import { useContext, useEffect } from 'react';
import { useLazyQuery } from "@apollo/client";
import {LOGIN} from "../queries/auth";
import { redirect } from "react-router-dom";
import { AuthContext } from '../config/auth-manager';
import { config } from '../config/config';

const Login = () => {

    const [searchParams] = useSearchParams();
    const [loginQuery] = useLazyQuery(LOGIN);
    const {setUser} = useContext(AuthContext);

    const code = searchParams.get('code');

    useEffect(() => {
        if (code && code !== "") {
            loginQuery({ variables: { input: code }}).then((res) => {
                if (res.data && res.data.login && !res.error && !res.loading) {
                    localStorage.setItem('sprinto-logs-token', JSON.stringify(res.data.login));
                    setUser(res.data.login.user);
                    return redirect('/');
                }
            });
        }
    }, [code, setUser, loginQuery]);

    return (
        <div>
            <a href={`https://discord.com/api/oauth2/authorize?client_id=${config.DISCORD_CLIENT_ID}&permissions=0&redirect_uri=${config.DISCORD_REDIRECT_URL}&response_type=code&scope=bot%20identify%20applications.commands`}>
                Login with discord
            </a>
        </div>
    )

}

export default Login;